.no-subscription-ui {
  margin: 10px;
  background-color: rgb(251, 251, 251);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card-content-element {
  margin: 2px 5px;
}

.card-content-element-title {
  margin: 2px 5px;
  font-weight: 600;
}

.subscription-content-element {
  margin: 5px;
}

.week-day {
  width: 20px;
  margin: 10px;
  padding: 4px;
  color: #002677;
  background-color: lightblue;
  cursor: pointer;
}

.selected-week-day {
  width: 25px;
  background-color: #002677;
  color: white;
  border: 2px solid gray;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
