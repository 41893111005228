.abyss-select-input-input-container {
  width: 75% !important;
}
.abyss-select-input-multi-input {
  width: 90% !important;
}

.abyss-select-input-multi-chip-group {
  width: 90% !important;
}
.abyss-chip-root {
  width: 50% !important;
}

.abyss-select-input-multi-label {
  font-size: small !important;
  color: #002677 !important;
}
.abyss-table-body {
  height: 20px;
}
.abyss-select-input-multi-chip-extra-count {
  background-color: #196ecf !important;
}
.abyss-select-input-multi-option-list-container {
  background-color: greenyellow;
}
