.filter-container-wrapper-sdrp {
  width: 90;
  margin: auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #002677;
}

.filter-container-sdrp {
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
  padding: 10px 5px;
}

.filter-button-wrapper-sdrp {
  /* display: flex;
  align-items: center; */
  /* margin-top: 30px; */
}

.report-table-record-count {
  text-align: right;
  margin: 10px 5px;
}
