.mr-3 {
  margin-right: 2rem !important;
}
.mapper-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  height: 40px;
}

.abyss-modal-header-container {
  background: #fcd299;
  /* //#196ecf; */
}
.abyss-modal-content-container {
  width: 40% !important;
}
.abyss-select-input-multi-search-container {
  min-height: 30px !important;
}
.abyss-select-input-multi-search-input {
  min-height: 30px !important;
}
.abyss-pagination-page-size-select-input {
  width: 65px !important;
}

#downshift-1-menu > li > div > div {
  display: none;
}
.abyss-c-lpkfhg {
  /* Adjust the position of the dropdown arrow */
  /* Example values to move the arrow 5 pixels to the right and 10 pixels down */
  position: relative;
  left: 15px;
  top: 0px;
}
.abyss-c-czsSGP-orientation-right {
  /* Adjust the position of the dropdown values */
  /* Example values to move the values 5 pixels to the right and 10 pixels down */
  display: none;
  position: absolute;
  width: 100%;
}
