.report-body-wrapper {
}

.header-wrapper {
  display: flex;
  align-items: center;
}

.report-title {
  /* margin-top: 15px !important; */
  font-size: 26px !important;
  color: #002677 !important; /*#002677*/
  font-family: 'UHCSerif';
  margin-left: 26px !important;
  font-weight: bold;
}

.filter-container-wrapper {
  width: 100%;
  margin: auto;
  height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #002677;
}

.multi-select-container {
  position: relative;
  z-index: 200;
}

.multi-select {
  width: 210px;
  margin: 0px 5px;
  z-index: inherit;
}

.multi-select-title {
  color: #002677;
}

.filter-container {
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
  padding: 10px 5px;
}

form {
  width: 95%;
}

.filter-button-wrapper {
  /* display: flex;
  align-items: center; */
  /* margin-top: 30px; */
}

.abyss-button {
  margin: 5px;
}

.report-table-record-count {
  text-align: right;
  margin: 10px 5px;
}

.report-table-wrapper {
  padding: 10px;
}

.report-options {
  /* position: relative;
  right: calc(-80%); */
}

.report-table {
  margin: 10px 5px;
  width: 90%;
  margin: auto;
}

.modal-filter-input-container {
  display: flex;
  align-items: center;
}

.modal-primary-buttons {
  /* width */
  width: 50%;
  margin: 2px auto;
}
