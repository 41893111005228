.greeting {
  font-size: medium !important;
}

.greeting-secondary {
  font-size: 14px !important;
}

.abyss-page-header-top-nav-menu {
  font-size: 20px !important;
}
.navbar-secondary-image {
  height: 65px !important;
  width: 80px !important;
  margin-left: 200px !important;
}
.logoTittle {
  /* margin-top: 15px !important; */
  font-size: 26px !important;
  color: #002677 !important; /*#002677*/
  font-family: 'UHCSerif';
  margin-left: 260px !important;
  font-weight: bold;
}
.abyss-brandmark {
  width: 100px !important;
  height: 30px !important;
  margin-top: 15px;
}
